<template>
  <div class="challenge-card">
    <!-- completed state -->
    <div v-if="completed" class="completed-state">
      <img :src="challenge.icon_complete_url" alt="challenge icon" />
      <div class="complete-body">
        <div class="complete-description">
          <span>{{ challenge.name }}</span>
          <span class="text-capitalize"> {{ $t("challenge_completed") }}!</span>
        </div>
        <div class="share-buttons" v-if="showShareOptions">
          <div @click="shareFacebook" class="share-button cursor-pointer">
            <img src="../../../assets/facebook_icon.svg" alt="facebook share" />
          </div>
          <div @click="shareTwitter" class="share-button cursor-pointer">
            <img src="../../../assets/twitter_icon.svg" alt="twitter share" />
          </div>
        </div>
      </div>
    </div>

    <!-- other types -->
    <div v-if="!completed" class="card-view">
      <div class="challenge-icon">
        <img :src="challenge.icon_incomplete_url" alt="challenge icon" />

        <div class="commits-counter" v-if="challenge.count_active_athletes">
          <i class="material-icons">person</i>
          <span>{{ numberWithCommas }}</span>
        </div>

        <div class="mobile-card-element mem-show-mobile">
          <div class="cd-buttons">
            <button
              v-if="canCommit && !isExpired"
              class="mem-btn btn-secondary-dark"
              @click="commit()"
            >
              <span>{{ $t("commit") }}</span>
            </button>
            <button v-if="isExpired" class="mem-btn btn-tertiary-dark" disabled>
              <span>{{ $t("expired") }}</span>
            </button>
            <div v-if="challenge.committed && !isExpired" class="commited-icon">
              <i class="material-icons">done</i>
            </div>
          </div>
          <div class="commits-counter" v-if="challenge.count_active_athletes">
            <i class="material-icons">person</i>
            <span>{{ numberWithCommas }}</span>
          </div>
        </div>
      </div>
      <div class="challenge-body">
        <div class="challenge-description">
          <div class="cd-text">
            <div class="challenge-name">{{ challenge.name }}</div>
            <div>{{ $t(challenge.description_translation_key) }}</div>
          </div>
          <div class="cd-buttons mem-show-desktop">
            <button
              v-if="canCommit && !isExpired"
              class="mem-btn btn-secondary-dark"
              @click="commit()"
            >
              <span>{{ $t("commit") }}</span>
            </button>
            <button v-if="isExpired" class="mem-btn btn-tertiary-dark" disabled>
              <span>{{ $t("expired") }}</span>
            </button>
            <div v-if="challenge.committed && !isExpired" class="commited-icon">
              <i class="material-icons">done</i>
            </div>
          </div>
        </div>
        <div class="challenge-progress">
          <v-progress-linear
            color="primary"
            :value="progressValue"
            height="2"
            background-color="#4D4D4D"
          ></v-progress-linear>
          <div class="progress-info">
            <div>
              <div
                v-if="
                  (challenge.steps_done || challenge.steps_done === 0) &&
                  challenge.steps_needed
                "
              >
                <span>{{ challenge.steps_done }}</span>
                <span class="colored-text"
                  >/{{ challenge.steps_needed }}
                  {{ $t(challenge.step_type) }}</span
                >
              </div>
            </div>
            <div>
              <span class="colored-text">{{ challengeDateInterval }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    showShareOptions: false,
  }),
  props: ["challenge"],
  computed: {
    ...mapGetters(["userData"]),
    progressValue() {
      if (this.challenge.steps_done && this.challenge.steps_needed)
        return Math.floor(
          (100 * this.challenge.steps_done) / this.challenge.steps_needed
        );
      else return 0;
    },
    challengeDateInterval() {
      let startDate = this.challenge.start_date;
      let endDate = this.challenge.end_date;

      if (!startDate || !endDate) return "";

      let daysLeft = moment(endDate).add(2, "days").diff(moment(), "days");

      if (daysLeft <= 30 && !this.isExpired)
        return daysLeft + " " + this.$t("days_left");

      let startMonth = moment(startDate).format("M");
      let endMonth = moment(endDate).format("M");

      if (startMonth === endMonth) {
        return (
          moment(startDate).format("MMM DD") +
          " - " +
          moment(endDate).format("DD")
        );
      } else
        return (
          moment(startDate).format("MMM DD") +
          " - " +
          moment(endDate).format("MMM DD")
        );
    },
    canCommit() {
      return (
        (!this.challenge.committed && !this.challenge.end_date) ||
        (!this.challenge.committed &&
          moment() <= moment(this.challenge.end_date))
      );
    },
    isExpired() {
      return moment().isAfter(moment(this.challenge.end_date), "day");
    },
    completed() {
      if (!this.challenge.steps_done && !this.challenge.steps_needed)
        return false;
      return this.challenge.steps_done === this.challenge.steps_needed;
    },
    numberWithCommas() {
      let count = this.challenge.count_active_athletes;
      if (count) return count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return "";
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
  },
  methods: {
    ...mapActions(["commitToChallenge", "getChallengeShareLink"]),
    async commit() {
      await this.commitToChallenge(this.challenge.id);
    },
    async shareFacebook() {
      let shareLink = "";
      let link = await this.getChallengeShareLink({
        challengeId: this.challenge.id,
        lang: this.userData.language,
      });
      if (link && link.url) {
        if (this.isMobile) {
          shareLink = `https://www.facebook.com/dialog/share?app_id=488548495450963&display=popup&hashtag=%23spartanchallenges&href=${
            location.origin
          }${link.url}&redirect_uri=${
            location.origin + "/tropaion/challenges"
          }`;
          document.location.href = shareLink;
        } else {
          shareLink = `${location.origin}${link.url}`;
          this.callFacebookUI(shareLink);
        }
      } else {
        console.log("Missing share link url", link);
      }
    },
    async shareTwitter() {
      let windowReference = window.open();
      let link = await this.getChallengeShareLink({
        challengeId: this.challenge.id,
        lang: this.userData.language,
      });
      if (link && link.url) {
        let shareLink = `https://twitter.com/intent/tweet?text=&url=${location.origin}${link.url}&hashtags=spartanrace,spartanchallenges`;
        windowReference.location = shareLink;
      } else {
        console.log("Missing share link url", link);
      }
    },
    callFacebookUI(link) {
      FB.ui(
        {
          method: "share",
          href: link,
          hashtag: "#spartanchallenges",
        },
        function (response) {
          console.log(response, "response");
        }
      );
    },
    openMobile(link) {
      let windowReference = window.open();
      windowReference.location = link;
    },
  },
  mounted() {
    console.log(this.isMobile, "is mobile");
  },
};
</script>
<style lang="scss" scoped>
.challenge-card {
  height: 182px;
  margin-bottom: 8px;

  .card-view {
    display: grid;
    grid-template-columns: 80px 1fr;
    gap: 40px;
    height: 100%;
    padding: 39px 38px 26px 40px;
    background-color: #121212;
    .cd-buttons {
      .mem-btn {
        height: 36px;
      }
      .commited-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        height: 36px;
        width: 36px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 100px;
        i {
          font-size: 18px;
          color: rgba(255, 255, 255, 0.1);
        }
      }
    }
  }
  .completed-state {
    display: grid;
    grid-template-columns: 80px 1fr;
    gap: 40px;
    align-items: center;
    height: 100%;
    background-color: #cf102d;
    padding: 0 38px 0 40px;
    img {
      height: 80px;
      width: 80px;
    }
    .complete-body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .complete-description {
        display: flex;
        flex-direction: column;

        font-weight: bold;
        font-size: 20px;
        line-height: 25px;

        color: #ffffff;
      }
      .share-buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 8px;
        .share-button {
          height: 46px;
          width: 46px;
          background-color: #ffffff;
          border-radius: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
            height: 18px;
            width: 18px;
          }
        }
      }
    }
  }

  .challenge-icon {
    img {
      height: 80px;
      width: 80px;
      margin-bottom: 14px;
    }
    .mobile-card-element {
    }
    .commits-counter {
      display: flex;
      flex-direction: row;
      justify-content: center;

      i {
        font-size: 16px;
        margin-right: 8px;
      }

      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
    }
  }
  .challenge-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .challenge-description {
      display: flex;
      flex-direction: row;

      .cd-text {
        width: 100%;
        margin-right: 25px;

        font-size: 14px;
        line-height: 19px;

        .challenge-name {
          font-size: 20px;
          font-weight: bold;
          line-height: 28px;

          margin-bottom: 8px;
        }
      }
    }
  }
  .challenge-progress {
    .progress-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 15px;

      margin-top: 18px;

      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      .colored-text {
        color: #9d9d9d;
      }
    }
  }
}
@media screen and (max-width: 499px) {
  .challenge-card {
    height: 272px;
    .card-view {
      padding: 23px 20px 33px 20px;
      grid-template-columns: 1fr;
      grid-template-rows: 75px 1fr;
      gap: 24px;
    }
    .completed-state {
      grid-template-columns: 1fr;
      grid-template-rows: 80px 1fr;
      gap: 14px;
      padding: 24px 20px 28px 20px;
      align-items: flex-start;
      img {
        height: 80px;
        width: 80px;
      }
      .complete-body {
        flex-direction: column;
        .complete-description {
        }
        .share-buttons {
          margin-top: 24px;
          display: flex;
          flex-direction: row;
          gap: 0;
          .share-button {
            margin-right: 8px;
          }
        }
      }
    }
    .challenge-icon {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      img {
        height: 75px;
        width: 75px;
      }
      .commits-counter {
        display: none;
        i {
        }
      }
      .mobile-card-element {
        .commits-counter {
          margin-top: 23px;
          display: flex;
        }
      }
    }
    .challenge-body {
      .challenge-description {
        .cd-text {
          margin-right: 0;
          .challenge-name {
          }
        }
        .cd-buttons {
          .mem-btn {
          }
          .commited-icon {
            i {
            }
          }
        }
      }
    }
    .challenge-progress {
      .progress-info {
        .colored-text {
        }
      }
    }
  }
}
// @media screen and (max-width: 375px) {
//   .challenge-card {
//     .card-view {
//     }
//     .completed-state {
//       img {
//       }
//       .complete-description {
//       }
//     }
//     .challenge-icon {
//       .commits-counter {
//         i {
//         }
//       }
//       img {
//       }
//     }
//     .challenge-body {
//       .challenge-description {
//         .cd-text {
//           .challenge-name {
//           }
//         }
//         .cd-buttons {
//           .mem-btn {
//           }
//           .commited-icon {
//             i {
//             }
//           }
//         }
//       }
//     }
//     .challenge-progress {
//       .progress-info {
//         .colored-text {
//         }
//       }
//     }
//   }
// }
</style>